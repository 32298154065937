.home-section-2 {
	background: linear-gradient(#EFEFEF, #fff);
	color: #285DAA;
	// background-image: url('../../images/home-section-2.svg');
	background-size: cover;
	background-repeat: no-repeat;
	& .title {
		font-family: 'CF Asty Std Bold';
		font-weight: bold;

	}

	& .description {
		font-family: 'CF Asty Std Book';
		font-weight: normal;
		font-size: 1.3125rem;
		color: #394f65;
		margin-bottom: 4.6875rem;

		&.white-color {
			color: #285DAA;
		}
	}

	.step-description{
		color: #5C5E60;
		font-family: 'CF Asty Std Book';
		font-weight: normal;
		font-size: 1.2rem;
		max-width:800px;
		margin:auto;
		text-align:center;
	}

}



