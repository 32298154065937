.label {
	font-family: 'CF Asty Std Medium';
	font-size: 0.9375rem;
	color: #8191a0;
	margin-bottom: 0.5rem;
}

.input {
	font-family: 'CF Asty Std Medium';
	color: #394f65;
	font-size: 0.9375rem;
	border-radius: 0.25rem;
	background-color: #f7f9fb;
	border: 1px solid #e1eaf5;
	padding: 0.625rem;
	&:focus {
		background-color: #f7f9fb;
		border-color: #e1eaf5;
		color: #394f65;
	}
}

.tooltip{
	margin-top:35px;
}

.input-bottom-spacer-25 {
	margin-bottom: 1.5625rem;
}

::-webkit-input-placeholder,
::placeholder {
	font-size: 0.8125rem;
	font-family: 'CF Asty Std Book';
}

@media (max-width: 962px) {
	.tooltip{
		margin-top:-20px;
		margin-bottom:20px;
	}
}