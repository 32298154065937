body {
    font-family: 'CF Asty Std Book';
    font-size: 1rem;
    font-weight: normal;
    margin-top: 125px;
}

@font-face {
    font-family: 'CF Asty Std Medium';
    src: url('./fonts/CFAstyStd-Medium.woff2') format('woff2'), url('./fonts/CFAstyStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CF Asty Std Book';
    src: url('./fonts/CFAstyStd-Book.woff2') format('woff2'), url('./fonts/CFAstyStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CF Asty Std Bold';
    src: url('./fonts/CFAstyStd-Bold.woff2') format('woff2'), url('./fonts/CFAstyStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.MuiStepLabel-label.MuiStepLabel-active {
    color: #ec1e31 !important;
}
.MuiStepLabel-label.MuiStepLabel-completed {
    color: #285daa !important;
}

.MuiTypography-body2 {
    font-family: 'CF Asty Std Medium' !important;
    font-size: 0.9375rem !important;
}

.CarouselItem {
    max-width: 1260px;
    margin: 0 auto;
}

.custom-button {
    border-radius: 2rem;

    max-width: 16.25rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    max-width: 16.25rem;
    width: 16.25rem;
    transition: all ease-in 0.3s;
    &:hover {
        &.white {
            background-color: white;
            border: 2px solid #fff;
        }
        & a {
            color: #285daa;
        }
    }
    &.white {
        background-color: #285DAA;
        border: 2px solid #285DAA;
        color: #fff;
    }
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: #285daa;
        }
    }
}

#custom-input-listbox {
    padding: 14px;
    box-shadow: 10px 5px 5px red;
    li {
        margin-bottom: 0.5rem;
        a {
            display: block;
            font-size: 0.9375rem;
            text-decoration: none;
            color: #8191a0;
            &:hover {
                color: #285daa;
            }
        }
    }
}

.style-1 {
    font-family: 'CF Asty Std Bold';
    font-weight: bold;
    font-size: 2.3125rem;
}

.style-2 {
    font-family: 'CF Asty Std Bold';
    font-weight: bold;
    line-height: 2.1875rem;
}
.style-3 {
    font-family: 'CF Asty Std Bold';
    font-weight: bold;
    line-height: 2.1875rem;
}

.style-4 {
    font-family: 'CF Asty Std Bold';
    font-weight: bold;
    font-size: 1.75rem;
}

.style-5 {
    font-family: 'CF Asty Std Bold';
    font-weight: bold;
    font-size: 2.3125rem;

    line-height: 2.875rem;
}

.white {
    color: white;
}

.primary-blue {
    color: #285daa;
}

.horizontal-divider {
    border-bottom: 1px solid rgba(112, 112, 112, 0.16);
    margin-bottom: 1.5rem;
}

.add-product-button {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    button {
        background-color: transparent;
        border: none;
        padding: 1.25rem 0;
        font-size: 1rem;
        font-family: 'CF Asty Std Medium';
        color: #285daa;
    }
}

.title-style-1 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'CF Asty Std Bold';
        color: #acfcff;
        font-size: 31px;
        background-image: url('../../assets/images/titles/title-style-1-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 2.75rem 0;
        background-position: 50% 50%;
    }
}

.MuiGrid-item {
    flex: 1 1;
    flex-basis: auto !important;
    flex-grow: 1 !important;
    color: #285daa;
    font-size: 0.9em;
    font-family: 'Asty CF Std Medium';
}

.MuiPaper-elevation8 {
    box-shadow: none !important;
}

.MuiGrid-container {
    justify-content: center;
    align-items: center;
}

.MuiStepper-alternativeLabel {
    justify-content: center;
}

.MuiStep-alternativeLabel {
    max-width: 340px;
}

.MuiOutlinedInput-root {
    padding: 8px 15px !important;
    &:hover {
        border-color: transparent !important;
        outline: none;
        border: none;
    }
}

.MuiOutlinedInput-notchedOutline {
    border: transparent !important;
}

.custom-input-styles {
    display: flex;
    border: 2px solid #2599fc;
    border-radius: 1.6875rem;
    align-items: center;
    padding: 0 0.75rem;
}

// Alert messages
.alert {
    padding-right: 1rem;
    border-radius: 0.25rem;

    p {
        padding: 0;
        margin: 0;
    }
    .alert-success {
        background-color: #9effc6;
        border: 1px solid #9effc6;
        color: #136c38;
        font-size: 1rem;
    }
}
.formtitle{
    display: block;
    padding: 0;
    margin-bottom: 30px;
}
.contact-box{
    display: block;
    margin-bottom: 50px;
    &__container{
        width: 100%;
        max-width: 985px;
        margin: 0 auto;
    }
    &__content{
        p{
            font-size: 18px;
            font-weight: normal;
            color: #285DAA;
            margin-bottom: 20px;
        }
        small{
            display: block;
            font-size: 15px;
            font-weight: normal;
            color: #8191A0;
            line-height: 1.2;
        }
    }
}

@media (max-width: 767px) {
    .MuiTypography-body2 {

        font-size: 0.8rem !important;
    }
}
