.page-title {
	color: #285daa;
	font-family: 'CF Asty Std Bold';

	h1 {
		text-align: center;
		font-size: 2.3125rem;
		line-height: 2.875rem;
		margin-bottom: 1.3125rem;
	}

	h2 {
		text-align: center;
		font-size: 1.75rem;
		line-height: 2.1875rem;
		margin-bottom: 1rem;
	}

	p {
		font-size: 1rem;
		color: #394f65;
		text-align: center;
		font-family: 'CF Asty Std Book';
	}
}

@media (max-width: 962px) {
	.page-title {

		margin-top: 0px!important;

	}
}
