.panel {
	// padding: 1.4375rem 0;
	padding-top: 1.4375rem;
	border-top: 1px solid #e1eaf5;
	margin-bottom: 1.25rem;
	.panel-heading {
		font-family: 'CF Asty Std Medium';
		font-size: 0.9375rem;
		color: #8191a0;
		cursor: pointer;
		margin-bottom: 1.4375rem;
	}

	.panel-collapse {
		overflow: hidden;
		transition: height ease-out 0.3s;
	}

	&.is-expanded {
		.panel-body {
			display: block;
		}
	}
}
