
.stepper-table {
    .table-grid {
        font-family: 'CF Asty Std Medium';
        display: grid;
        grid-template-columns: (1fr);
        color: #394f65;
        margin-bottom: 1rem;

        .table-title {
            text-align: center;
            display: grid;
            grid-gap: 0.4375rem;
            grid-template-columns: (7% 2fr 20% 10%);
            border-radius: 0.25rem;
            background-color: #e0e5e9;
            margin-bottom: 1.1875rem;
            div {
                padding: 1rem;
                font-size: 1rem;
            }
        }
        .table-body {
            display: grid;
            grid-gap: 0.4375rem;
            grid-template-columns: (7% 2fr 20% 10%);
            margin-bottom: 1.0625rem;
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                background-color: #fff;
                font-size: 0.9375rem;
                border-radius: 0.25rem;
            }
        }
    }

    .table-summary {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        color: #394f65;
        font-family: 'CF Asty Std Medium';
        margin-bottom: 0.375rem;

        &.total-value {
            font-family: 'CF Asty Std Bold';
            color: #285daa;
            font-size: 1.25rem;
            margin-top: 0.625rem;
        }
    }

    .table-payment-deitails-container {
        font-family: 'CF Asty Std Medium';
        border-top: 1px solid rgba(112, 112, 112, 0.27);
        margin-top: 2.5625rem;
        padding-bottom: 2.5625rem;
        .table-payment-details {
            margin-top: 2.5625rem;
            display: flex;
            justify-content: space-between;
            gap: 70px;

            .table-payment-details-holder {
                max-width: 18.75rem;
            }

            .table-payment-details-comments {
                font-family: 'CF Asty Std Medium';
                color: #8191a0;
                font-size: 0.9375rem;
            }
            .table-payment-details-title {
                font-size: 1.125rem;
                color: #285daa;
                margin-bottom: 1rem;
            }
            .table-payment-details-elements {
                font-size: 0.9375rem;
                margin-bottom: 0.375rem;
                span {
                    &:first-child {
                        padding-right: 0.3125rem;
                    }
                }
            }
        }
    }

    .grey-color-1 {
        color: #8191a0;
    }

    .blue-color-1 {
        color: #394f65;
    }
}

/* MOBILE VIEW */
.step-3-mobile-view {
    margin-bottom: 1.875rem;
    .product-title {
        background-color: #2599fc;
        border-radius: 0.25rem 0.25rem 0 0;
        color: #fff;
        padding: 0.625rem 1.375rem;
        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 0.875rem;
            color: #fff;
        }
    }
    .card-body {
        background-color: #fff;
        padding: 0.75rem;
        border-radius: 0.25rem;
    }

    .card-container {
        display: flex;
        font-family: 'CF Asty Std Medium';
        font-size: 0.8125rem;
        margin-bottom: 1rem;

        .card-title {
            margin-right: 1rem;
            color: #8191a0;
            width: 35%;
            margin-bottom: 0;
        }
        .card-detail {
            color: #394f65;
            word-break: break-word;
            width: 65%;
        }
    }
}

@media (max-width: 768px) {
    .stepper-table {
        .table-payment-deitails-container {
            .table-payment-details {
                flex-direction: column;
            }
        }
    }
}

.bformc{
    grid-template-columns: (5% 18% 3fr 14% 8% 8% 8%!important);
}
