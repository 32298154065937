.slideshow {
	.position-elements-left {
		position: absolute;
		top: 35%;
		transform: translateY(-50%);
		left: 5%;
		color: #fff;
		z-index: 1010;
		width: 35%;
		h2 {
			font-size: 2rem;
			font-weight: bold;
		}
		p {
			font-size: 1.25rem;
		}
	}

	.relative-position {
		position: relative;
	}

	.img-overlay {
		&::after {
			content: '';
			top: 0;
			left: 0%;
			border-radius: 10px;
			position: absolute;
			z-index: 1000;
			width: 100%;
			height: 100%;
			background: rgb(96, 100, 106);
			background: linear-gradient(
				90deg,
				rgba(96, 100, 106, 0.7567401960784313) 0%,
				rgba(0, 212, 255, 0) 100%
			);
		}
		img {
			border-radius: 10px;
		}
	}
	.border-overlay {
		position: relative;
		border-radius: 12px;
		overflow: hidden;
	}

	iframe {
		height: 100%;
		min-height: 620px;
		border-radius: 10px;
	}
}
@media screen and (max-width: 990px) {
	.position-elements-left {
		width: 100%;
		h2 {
			font-size: 1.25rem;
		}
		p {
			font-size: 1rem;
		}
	}

	.img-overlay {
		&::before {
			height: 86%;
		}
	}

	iframe {
		height: 100%;
		min-height: 400px!important;
		border-radius: 10px;
	}
}

@media screen and (max-width: 764px) {

	iframe {
		height: 100%;
		min-height: 300px!important;
		border-radius: 10px;
	}

}

@media screen and (max-width: 764px) {

	iframe {
		height: 100%;
		min-height: 200px!important;
		border-radius: 10px;
	}

}


