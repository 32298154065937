.top-navigation {
    background-color: #fff;
}

.top-navigation-holder {
    display: flex;
    align-items: center;
    padding: 1rem 0;
}

.top-ribbon {
    background-color:#2b5da9;
    color:#fff;
    font-size:12px;
    padding:10px;
    text-align:center;
}
.menu-links {

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 1.875rem;
        li {
            list-style-type: none;
            display: flex;
            align-items: center;

            &:nth-child(3) {
                img {
                    display: none;
                }
            }

            img {
                width: 1.875rem;
                height: auto;
                margin-right: 0.625rem;
                cursor: pointer;
            }
            a {
                font-family: 'CF Asty Std Medium';
                text-decoration: none;
                color: #285daa;
                font-size: 1rem;
            }
            
        }
    }
}

.logo-search-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
}



@media (max-width: 962px) {
    .top-navigation {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 1rem;
        margin-bottom: 0rem;
        .top-navigation-holder {
            text-align: center;
            flex-direction: column;
        }
    }
    .logo-search-holder {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.125rem;
        gap: 1rem;
    }

    .sticky {
        position: relative;
        top: 0;
        z-index: 9999;
        width: 100%;
    }

    body {margin-top:0px!important;}

    .menu-links {
        ul {
            li {
                a {
                    font-size: 0.875rem;
                    text-align: left;
                }
            }
        }
    }
}
