.radio-collapse-container {
    margin-bottom: 1rem;
    .radio-collapse-title {
        background-color: #285daa;
        color: #fff;
        padding: 1rem;
        font-family: 'CF Asty Std Medium';
        margin-bottom: 0.625rem;
        border-radius: 0.25rem;

        label {
            margin: 0 0 0 1rem;
            color: #fff;
        }
    }

    .radio-collapse-content {
        overflow: hidden;
        transition: height ease-out 0.3s;
    }

    .radio-collapse-content {
        &.is-expanded {
            display: block;
        }
    }
}
