.menu-title {
    color: #285daa;
    font-size: 1rem;
    font-family: 'CF Asty Std Medium';
    padding-bottom: 0.6875rem;
}

.vertical-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.category-item {
    background-color: transparent;
    min-height: unset;
    text-align: left;
    margin-bottom: 1rem;

    a {
        text-decoration: none;
        font-family: 'CF Asty Std Medium';
        font-size: 0.8rem;
        font-weight: normal;
        color: #94979b;
        transition: all ease-in 0.25s;
        display: flex;
        align-items: center;
        background:#F7F9FB;
        border:1px solid #dedede;
        border-radius: 5px;
        padding:10px;
        &:hover {
            color: #fff;
            background: #285DAA;
        }

        &::before {
            content: '';
            background-image: url('../../assets/images/svg/category-icon.svg');
            background-size: 16px 8px;
            width: 16px;
            height: 8px;
            margin-right:5px;
        }
    }
}

@media (max-width: 767px) {
    .menu-title {
        text-align: center;
        border: none;
    }

    .vertical-list {
        margin: 0 auto;
    }

    .category-item {
        background-color: #f5f7f9;
        border-radius: 0.375rem;
        padding: 0rem;
        text-align: center;
        margin-bottom: 22px;
        min-height: auto;


    }
    .category-item {
        a {
            font-size: 0.7rem;
            line-height: 1.0625rem;
            text-align:left;
        }
    }


}
