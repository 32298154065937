.img-fluid {
	max-width: 100%;
	height: auto;
}

.store-container {
	font-family: 'CF Asty Std Medium';
	background-color: #fff;
	font-size: 1.0625rem;
	padding: 2rem 1.5rem 1rem 1.5rem;
	margin: 0.5rem;
	border-radius: 0.375rem;
	min-height: 25rem;
	margin-bottom: 2.8125rem;
	.store-image-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
		width: 100%;
		max-width: 200px;
		height: 200px;
		background-color: #F2F1F7;
		border-radius: 5px;
		margin: 0 auto;
                
		img {
			border-radius: 0.375rem;
		}
	}

	& ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			font-family: 'CF Asty Std Medium';
			line-height: 1.0625rem;
			margin-bottom: 1.0625rem;
			color: #8191a0;
			display: flex;
			align-items: center;
			font-size: 0.875rem;
			svg {
				margin-right: 10px;
			}
		}
	}
}

.store-link {
	font-family: 'CF Asty Std Medium';
	text-align: center;
	border-bottom: 1px solid rgba(112, 112, 112, 0.19);
	padding-bottom: 1.125rem;
	margin-bottom: 1.125rem;
	margin-top: 1.125rem;
	text-align: center;
	display: block;
	font-size: 1.0625rem;
	color: #394f65;
	text-decoration: none;
	position: relative;

	&::after {
		content: '';
		background-color: #285daa;
		width: 50%;
		height: 2px;
		position: absolute;
		left: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: all ease-in 0.3s;
	}
	&:hover {
		color: #285daa;
		&::after {
			opacity: 1;
		}
	}
}

.store-information-list {
	margin: 0 !important;
	padding: 0 !important;
}

.truncate {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
