$container-max-widths: (
	xxl: 1360px,
);

$display-font-sizes: (
	7: 2.1875rem,
	8: 2rem,
);

$spacer: 1rem;
$spacers: (
	0: 0,
);

@for $i from 1 through 100 {
	$spacers: map-merge(
		$spacers,
		(
			$i: (
				$i * $spacer,
			),
		)
	);
}

$fontSize: 0.0625rem;
$display-font-sizes: (
	0: 0,
);

@for $i from 1 through 100 {
	$display-font-sizes: map-merge(
		$display-font-sizes,
		(
			$i: (
				$i * $fontSize,
			),
		)
	);
}

$size: 1rem;
$sizes: (
	0: 0,
);

@for $i from 1 through 100 {
	$sizes: map-merge(
		$sizes,
		(
			$i: (
				$i * $size,
			),
		)
	);
}

$input-btn-focus-box-shadow: transparent;
$input-placeholder-color: #8d9aa6;
$form-select-border-radius: 0.25rem;
$form-select-focus-box-shadow: transparent;
$form-select-focus-border-color: #e1eaf5;
$form-select-font-size: 0.9375rem;

$input-font-family: 'CF Asty Std Medium';
$input-color: #394f65;
$input-border-color: #e1eaf5;
$input-font-size: 0.9375rem;
$input-focus-border-color: #e1eaf5;
$input-bg: #f7f9fb;

$form-select-padding-y: 0.625rem;
$form-select-padding-x: 0.625rem;

$form-select-color: #394f65;
$padding-x: 30px;
