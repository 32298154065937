.steps-container {
	font-family: 'CF Asty Std Bold';
	.steps-section {
		display: flex;
		gap: 4.375rem;
		margin-bottom: 3.75rem;
		justify-content: center;
		align-items: center;

		.step-image {
			margin-bottom: 1.25rem;
			img {
				height: 110px;
				max-height: 110px;
			}
		}

		.step-title {
			background-color: #fff;
			border-radius: 1.125rem;
			max-width: 10rem;
			margin: 0 auto;
			font-size: 1.25rem;
			color: #285daa;
			text-align: center;
			padding: 0.2rem 30px;
			margin-bottom: 1.25rem;
		}

		.step-description {
			font-family: 'CF Asty Std Book';
			color: #5C5E60;
			font-size: 1rem;
			max-width: 18.125rem;
			line-height: 1.4375rem;
		}

		.step-divider {
			transform: rotate(-90deg);
		}
	}
	.step-slogan {
		color: #285DAA;
		font-size: 1.375rem;
	}

	.buttons-holder {
		display: flex;
		gap: 4rem;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (max-width: 991px) {
	.steps-container {
		.steps-section {
			gap: 3rem;
			flex-direction: column;
			.step-divider {
				transform: unset;
			}
		}
		.buttons-holder {
			flex-direction: column;
			gap: 2rem;
		}
	}
}
