.custom-card-container {
	margin-bottom: 2rem;
	& .custom-card-body {
		padding: 1.875rem;
		background-color: #fff;
		border-radius: 0.875rem;
		position: relative;
	}
}

.custom-card-title {
	display: inline-block;
	background: linear-gradient(#285daa, #226bb8);
	color: #fff;
	border-radius: 0.5625rem 0.5625rem 0 0;
	padding: 15px 25px;
	margin-bottom: -10px;
	font-family: 'CF Asty Std Bold';
	font-size: 1.0625rem;
	position: relative;
	span {
		font-size: 1.375rem;
	}
}
