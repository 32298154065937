ul.horizontal-list {
	margin: 0;
	padding: 0 0 1.5rem 0;
	display: flex;
	gap: 3.125rem;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	& li {
		list-style-type: none;
		& a {
			text-decoration: none;
			color: #285daa;
			font-size: 0.9375rem;
			font-family: 'CF Asty Std Medium';
			font-weight: 500;
		}
	}
}

ul.disclaimer-menu {
	margin: 0;
	padding: 0 0 1rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	flex-wrap: wrap;

	li {
		list-style: none;
		a {
			font-size: 0.8125rem;
			color: #707070;
			font-family: 'CF Asty Std Book';
			text-decoration: none;
		}
	}
}

ul.categories-menu {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		font-family: 'CF Asty Std Medium';
		font-size: 0.9375rem;
		font-weight: normal;
		color: #94979b;
		line-height: 2.1875rem;
		transition: all ease-in 0.25s;
		&:hover {
			color: #285daa;
		}
	}
}

@media screen and (max-width: 991px) {
	ul.horizontal-list {
		gap: 1rem;
		padding: 1rem;
		flex-direction: column;
	}
}
