.merchant-categories {
    padding: 40px 0;
    min-height: 11.5rem;
}

.carousel-item {
    font-family: 'CF Asty Std Medium';
    color: #8191a0;
    font-size: 15px;
    border-bottom:1px solid #fff;
    padding-bottom:5px;
    &:hover {
        color: #285daa;
    }
}

.active {
    color: #285daa;
    border-bottom:1px solid #285daa;
    padding-bottom:5px;
}
.subcategories-box{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    &__item{
        padding: 8px 16px;
        font-family: 'CF Asty Std Medium';
        color: #8191a0;
        font-size: 17px;
        border-radius: 4px;
        margin: 0 2px;
        &--active{
            background-color: #285daa;
            color: #fff;
        }
        &:hover{
            background-color: #285daa;
            color: #fff;
        }
    }
}