.accordion {
    margin-bottom: 60px;

    .accordion-title {
        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'CF Asty Std Medium';
            font-size: 1rem;
            color: #fff;
            cursor: pointer;
            background-color: #2599fc;
            border-radius: 0.5rem;
            padding: 1.125rem;
            margin-bottom: 2px;
        }
    }

    .accordion-collapse {
        overflow: hidden;
        transition: height ease-out 0.3s;
    }
    .accordion-body {
        padding: 1rem 0;
    }

    &.is-expanded {
        .accordion-body {
            display: block;
        }
    }
}

.accordion-category-title {
    margin: 1.1875rem 0;
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'CF Asty Std Book';
        color: #285daa;
        font-size: 22px;
    }
}

.accordion-category-title:first-child{margin-top:0px;}
