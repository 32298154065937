.page-content {
    background-color: #f5f7f9;
    font-family: 'CF Asty Std Book';
    font-size: 1rem;
    color: #5c5e60;
    line-height: 1.375rem;

    p {
        line-height: 1.375rem;
    }

    ol,
    ul,
    dl {
        padding: 1rem 0 1rem 1.5rem;
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.375rem;
    }

    h4 {
        color: #285daa;
        margin: 2.2rem 0 1rem 0;
        font-size: 1.5rem;
    }

    label {
        font-family: 'CF Asty Std Medium';
        font-size: 0.9375rem;
        color: #8191a0;
        margin-bottom: 0.5rem;
    }

    select {
        font-family: 'CF Asty Std Medium';
        color: #394f65;
        font-size: 0.9375rem;
        background-color: #f7f9fb;
        border-color: #e1eaf5;
        padding: 0.625rem;
    }
    .formTooltip {width:20px;}
    .formTooltipInside{margin-top:35px;}
}

.formTooltip {width:20px;}
.formTooltipInside{margin-top:35px;}

@media (max-width: 962px) {
    .page-content {
            margin-top: 0px!important;

            .formTooltipInside{margin-top:0px;}
    }
}
