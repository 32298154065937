.select {
	font-family: 'CF Asty Std Medium';
	color: #394f65;
	font-size: 0.9375rem;
	background-color: #f7f9fb;
	border-color: #e1eaf5;
	padding: 0.625rem;
}

.label {
	font-family: 'CF Asty Std Medium';
	font-size: 0.9375rem;
	color: #8191a0;
	margin-bottom: 0.5rem;
}

.select-bottom-spacer-25 {
	margin-bottom: 1.5625rem;
}
