.category-selection{
    display: block;
    border-radius: 4px;
    border: 1px solid #E1EAF5;
    background-color: #F7F9FB;
    padding: 17px;
    margin-bottom: 20px;
    &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #285DAA;
        border-radius: 4px;
        padding: 12px;
        cursor: pointer;
        position: relative;
    }
    &__button-label{
        font-family: "CF Asty Std", sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
    }
    &__button-icon{
        display: block;
        padding: 12px 16px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
    }
    &__results{
        display: block;
        padding-top: 20px;
        margin-bottom: -10px;
        position: relative;
    }
    &__results-label{
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: #8191A0;
        padding: 0;
        margin-bottom: 10px;
    }
    &__results-content{

    }
    &__title{
        display: block;
        font-family: "CF Asty Std", sans-serif;
        font-size: 19px;
        color: #285DAA;
        padding: 10px 0;
    }
    &__text{
        display: block;
        font-family: "CF Asty Std", sans-serif;
        font-size: 16px;
        color: #394F65;
    }
}
.result-items{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.result-item{
    background-color: #E1EAF5;
    border-radius: 4px;
    padding: 10px 15px;
    font-family: "CF Asty Std", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #394F65;
    text-align: left;
    margin: 0 10px 10px 0;
    &__label{
        margin-right: 10px;
    }
    &__close{
        margin-left: auto;
        cursor: pointer;
    }
}

.multi-box{
    display: block;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 19px;
    box-shadow: 0 22px 44px rgba(#143058, .17);
    padding: 16px 15px 35px;
    opacity: 0;
    visibility: hidden;
    @media(min-width: 768px){
        padding: 16px 25px 35px;
    }
    &.opened{
        opacity: 1;
        visibility: visible;
    }
    &.closed{
        .multi-box__body{
            visibility: hidden;
        }
    }
    &__header{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        a{
            display: inline-block;
            font-family: "CF Asty Std", sans-serif;
            font-size: 15px;
            color: #285DAA;
            span{
                display: inline-block;
                min-width: 26px;
                text-align: right;
            }
            &:hover,
            &:focus{
                text-decoration: underline;
            }
        }
    }
    &__item{

    }
    &__body{
        background-color: #F7F9FB;
        opacity: 0;
        visibility: hidden;
        height: 0;
        padding: 0;
        &.opened{
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 10px 10px 30px;
            @media(min-width: 768px){
                padding: 10px 30px 30px;
            }
        }
    }
    &__head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "CF Asty Std", sans-serif;
        font-size: 15px;
        color: #394F65;
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #E1EAF5;
        @media(min-width: 768px){
            padding: 10px 15px;
        }
        &:hover,
        &:focus{
            background-color: #285DAA;
            color: #fff;
        }
    }
    &__label{
        font-family: "CF Asty Std", sans-serif;
        font-size: 15px;
        font-width: 500;
    }
    &__drop{
        padding: 0 5px;
    }
    &__extra-cat{
        padding-top: 20px;
    }
    &__footer{
        padding-top: 30px;
    }
    &__actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__button{
        font-family: "CF Asty Std", sans-serif;
        font-size: 16px;
        border-radius: 4px;
        line-height: 1;
        padding: 12px 18px;
        font-weight: bold;
        &--cancel{
            margin-right: 10px;
            background-color: #E7ECF4;
            color: #8D9AA6;
            &:hover,&:focus{
                background-color: darken( #E7ECF4, 10%);
                color: #8D9AA6;
            }
        }
        &--add{
            background-color: #285DAA;
            color: #fff;
            &:hover,&:focus{
                background-color: darken( #285DAA, 10%);
                color: #fff;
            }
        }
    }
}
.multi-box label.multi-box__head{
    font-size: 15px;
    color: #394F65;
    cursor: pointer;
    margin: 0;
    padding: 10px 15px;
    &:hover,
    &:focus{
        background-color: #285DAA;
        color: #fff;
    }
}

.c-custom-checkbox{
    $checkbox: &;
    display: flex;
    input[type=checkbox]{
        opacity: 0;
        position: absolute;
    }
    svg{
        flex-basis: 20px;
        max-width: 20px;
        margin: 0;
    }
    label,
    span.label{
        display: block;
        font-size: 13px;
        width: 100%;
    }
    #{$checkbox}__empty{
        display: block;
    }
    #{$checkbox}__checked{
        display: none;
    }
    input:checked ~ #{$checkbox}__empty{
        display: none;
    }
    input:checked ~ #{$checkbox}__checked{
        display: block;
    }
}