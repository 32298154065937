.alphaEngine {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
}

.alphavetAll {
    flex: 0.1;
    font-family: 'CF Asty Std Bold';
    color: #285daa;
    cursor: pointer;
    padding: 0px 10px;
    border-radius: 0.5625rem;
    div {
        text-align: center;
        padding: 5px 10px;
        border-radius: 0.5625rem;
        transition: all ease-in 0.3s;
        margin-right: 5px;
        &:hover {
            background-color: #285daa;
            color: #fff;
        }
    }
}

.alphavetMobile {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
    font-family: 'CF Asty Std Bold';
    color: #285daa;
    cursor: pointer;
    padding: 0px 10px;
    border-radius: 0.5625rem;
    text-align: center;
    max-width:300px;
    margin:auto;
    div {
        text-align: center;
        padding: 10px;
        border-radius: 0.5625rem;
        transition: all ease-in 0.3s;
        &:hover {
            background-color: #285daa;
            color: #fff;
        }
    }
}

.active {
    background-color: #285daa;
    color: #fff;
}

.alphavetEl {
    flex: 2;
    flex-wrap: wrap;
}

.alphavetEn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: 'CF Asty Std Bold';
    color: #285daa;
    cursor: pointer;
    margin-bottom: 1.25rem;
    div {
        padding: 5px 10px;
        border-radius: 0.5625rem;
        transition: all ease-in 0.3s;
        margin-right: 5px;
        &:hover {
            background-color: #285daa;
            color: #fff;
        }
    }
    .active {
        background-color: #285daa;
        color: #fff;
    }

}

.inActive {background-color: #fff!important;color:#C1C4C9!important;cursor: default!important;}
.inActive:hover {background-color: #fff!important;color:#C1C4C9!important;cursor: default!important;}


.alphavetGr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 7.7%);
    font-family: 'CF Asty Std Bold';
    color: #285daa;
    cursor: pointer;
    div {
        padding: 5px 10px;
        border-radius: 0.5625rem;
        transition: all ease-in 0.3s;
        margin-right: 5px;
        &:hover {
            background-color: #285daa;
            color: #fff;
        }
    }
}


@media only screen and (max-width: 600px) {
    .tabText {font-size:14px!important;}

}


